import { createAsyncThunk } from '@reduxjs/toolkit';
import * as API from '../../API';
import * as toast from '../../components/Toast'
import { Cookies } from 'react-cookie'
import { GetCookie } from '../../helper/functions/general-func'

export const getTokenACTION = createAsyncThunk(
    'Authentication/getToken',
    async (data) => {
        let origin = window.location;
        let subDomain = null;
        if (process.env.REACT_APP_FIXED_DOMAIN !== 'undefined') {
          subDomain = process.env.REACT_APP_FIXED_DOMAIN;
        } else {
            subDomain = origin.host.toString().toLowerCase().replace("www.", "");
            if((subDomain.indexOf("xyzmenu.com")!="-1"  || subDomain.indexOf("softworldinfotech.com")!="-1" || subDomain.indexOf("ayse.in")!="-1") && subDomain.split('.').length > 2){
                subDomain = subDomain.split('.')[0];
            }
         
        }

        let rqData = {
            ...data,
            subDomain:subDomain
        }
        return API.apiCall("/restaurant/web/gettoken", "POST", rqData, false)
            .then((response) => {

                if (response.status === 200 || response.status === 201) {
                    if (!response.data.hasError) {
                        if (!GetCookie('token')) {

                            localStorage.setItem('main', response.data.data.tokenDetails.token)
                            localStorage.setItem('LD', response.data.data.user)
                            let date = new Date(response.data.data.tokenDetails.expires)
                            const cookies = new Cookies()
                            cookies.set('token', response.data.data.tokenDetails.token, { path: '/', expires: date })
                        }
                        // toast.success(response.data.message.message)
                        return {
                            ...response.data
                        }
                    } else {
                        toast.error(response.data.message.message)

                        return Promise.reject({
                            ...response.data.message
                        })
                    }
                }
            })
            .catch((error) => {

                return Promise.reject({
                    ...error
                })
            })
    }
);

export const signupACTION = createAsyncThunk(
    'Authentication/signup',
    async (data) => {
        return API.apiCall("/restaurant/web/user/signup", "POST", data, false)
            .then((response) => {

                if (response.status === 200 || response.status === 201) {
                    if (!response.data.hasError) {
                        // toast.success(response.data.message.message)
                        return {
                            ...response.data
                        }
                    } else {
                        toast.error(response.data.message.message)

                        return Promise.reject({
                            ...response.data.message
                        })
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error
                })
            })
    }
);
export const changePasswordACTION = createAsyncThunk(
    'Authentication/changePassword',
    async (data) => {
        return API.apiCall("/restaurant/web/user/changepassword", "POST", data, false)
            .then((response) => {

                if (response.status === 200 || response.status === 201) {
                    if (!response.data.hasError) {
                        toast.success(response.data.message.message)
                        return {
                            ...response.data
                        }
                    } else {
                        toast.error(response.data.message.message)

                        return Promise.reject({
                            ...response.data.message
                        })
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error
                })
            })
    }
);
export const forgotPasswordACTION = createAsyncThunk(
    'Authentication/forgotPassword',
    async (data) => {
        return API.apiCall("/restaurant/web/user/forgotpassword", "POST", data, false)
            .then((response) => {

                if (response.status === 200 || response.status === 201) {
                    if (!response.data.hasError) {
                        // toast.success(response.data.message.message)
                        return {
                            ...response.data,

                        }
                    } else {
                        toast.error(response.data.message.message)

                        return Promise.reject({
                            ...response.data.message
                        })
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error
                })
            })
    }
);
export const updatePasswordACTION = createAsyncThunk(
    'Authentication/updatePassword',
    async (data) => {
        return API.apiCall("/restaurant/web/user/updatepassword", "POST", data, false)
            .then((response) => {

                if (response.status === 200 || response.status === 201) {
                    if (!response.data.hasError) {
                        // toast.success(response.data.message.message)
                        return {
                            ...response.data
                        }
                    } else {
                        toast.error(response.data.message.message)

                        return Promise.reject({
                            ...response.data.message
                        })
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error
                })
            })
    }
);

export const validateOTPACTION = createAsyncThunk(
    'Authentication/validateOTP',
    async (data) => {
        return API.apiCall("/restaurant/web/user/validateotp", "POST", data, false)
            .then((response) => {

                if (response.status === 200 || response.status === 201) {
                    if (!response.data.hasError) {
                        localStorage.setItem('main', response.data.data.tokenDetails.token)
                        localStorage.setItem('usersInfo', JSON.stringify({ user: response.data.data.user }))
                        let date = new Date(response.data.data.tokenDetails.expires)
                        const cookies = new Cookies()
                        cookies.set('token', response.data.data.tokenDetails.token, { path: '/', expires: date })
                        // toast.success(response.data.message.message)
                        return {
                            ...response.data
                        }
                    } else {
                        toast.error(response.data.message.message)

                        return Promise.reject({
                            ...response.data.message
                        })
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error
                })
            })
    }
);

export const fpvalidateOTPACTION = createAsyncThunk(
    'Authentication/fpvalidateOTP',
    async (data) => {
        return API.apiCall("/restaurant/web/user/fpvalidateotp", "POST", data, false)
            .then((response) => {

                if (response.status === 200 || response.status === 201) {
                    if (!response.data.hasError) {

                        // toast.success(response.data.message.message)
                        return {
                            ...response.data
                        }
                    } else {
                        toast.error(response.data.message.message)

                        return Promise.reject({
                            ...response.data.message
                        })
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error
                })
            })
    }
);

export const resendOTPACTION = createAsyncThunk(
    'Authentication/resendOTP',
    async (data) => {
        return API.apiCall("/restaurant/web/user/resendotp", "POST", data, false)
            .then((response) => {

                if (response.status === 200 || response.status === 201) {
                    if (!response.data.hasError) {
                        // toast.success(response.data.message.message)
                        return {
                            ...response.data
                        }
                    } else {
                        toast.error(response.data.message.message)

                        return Promise.reject({
                            ...response.data.message
                        })
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error
                })
            })
    }
);

export const loginACTION = createAsyncThunk(
    'Authentication/login',
    async (data) => {
        return API.apiCall("/restaurant/web/user/login", "POST", data, false)
            .then((response) => {

                if (response.status === 200 || response.status === 201) {
                    if (!response.data.hasError) {
                        localStorage.setItem('main', response.data.data.tokenDetails.token)
                        localStorage.setItem('usersInfo', JSON.stringify({ user: response.data.data.user }))
                        let date = new Date(response.data.data.tokenDetails.expires)
                        const cookies = new Cookies()
                        cookies.set('token', response.data.data.tokenDetails.token, { path: '/', expires: date })
                        // toast.success(response.data.message.message)
                        return {
                            ...response.data
                        }
                    } else {
                        toast.error(response.data.message.message)

                        return Promise.reject({
                            ...response.data.message
                        })
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error
                })
            })
    }
);

export const saveAdderssACTION = createAsyncThunk(
    'Authentication/saveAdderss',
    async (data) => {
        return API.apiCall("/restaurant/web/user/address/save", "POST", data, false)
            .then((response) => {

                if (response.status === 200) {
                    if (!response.data.hasError) {
                        //console.log("response.data ", response.data);
                        toast.success(response.data.message.message)
                        return {
                            data: {
                                id: response.data.data,
                                ...response.data,
                            },
                            message: {
                                code: response.data.message.code,
                                type: response.data.message.type,
                                message: response.data.message.message
                            },
                            newRecord: data.id ? false : true
                        }
                    } else {
                        //console.log("response error", response.data.message);
                        toast.error(response.data.message.message)
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error
                })
            })
    }
);

export const getAllAdderssACTION = createAsyncThunk(
    'Authentication/getAllAdderss',
    async (data) => {
        return API.apiCall("/restaurant/web/user/address/getall", "POST", data, false)
            .then((response) => {

                if (response.status === 200 || response.status === 201) {
                    if (!response.data.hasError) {
                        // toast.success(response.data.message.message)
                        return {
                            ...response.data
                        }
                    } else {
                        toast.error(response.data.message.message)

                        return Promise.reject({
                            ...response.data.message
                        })
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error
                })
            })
    }
);

export const changestatusAdderssACTION = createAsyncThunk(
    'Authentication/changestatusAdderss',
    async (data) => {
        return API.apiCall("/restaurant/web/user/address/changestatus", "POST", data, false)
            .then((response) => {

                if (response.status === 200) {
                    if (!response.data.hasError) {
                        //console.log("response.data ", response.data);
                        toast.success(response.data.message.message)
                        return {
                            ...response.data,
                            id: data.id,
                        };
                    } else {
                        //console.log("response error", response.data.message);
                        toast.error(response.data.message.message)
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error
                })
            })
    }
);

export const deleteAdderssACTION = createAsyncThunk(
    'Authentication/deleteAdderss',
    async (data) => {
        return API.apiCall("/restaurant/web/user/address/changestatus", "POST", data, false)
            .then((response) => {

                if (response.status === 200) {
                    if (!response.data.hasError) {
                        toast.success(response.data.message.message);
                        return {
                            ...response.data,
                            data: {
                                ...data,
                            },
                        };
                    } else {
                        toast.error(response.data.message.message);
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error
                })
            })
    }
);